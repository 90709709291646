import { useSelector } from "react-redux";
import { formatDate, formatPrice, getBreed, getStringValue } from "./lib/FormatData";
import { Parameter } from "./PetFullCardText";
import { CatCard } from "./CommonData/DataTypes";
import { useState } from "react";

interface PetShortCardProps {
    petCard: CatCard;
    isFavorite: boolean;
}

export default function PetShortCard(props: PetShortCardProps): JSX.Element {
    const { userIsAuthenticated } = useSelector((state:any) => state.user);

    function getPriceString(petPrice: string | null, breedPrice: string | null): string {
        const numPetPrice = parseFloat(petPrice ?? '');
        const numBreedPrice = parseFloat(breedPrice ?? '');
        let priceStr: string = '';

        if (!isNaN(numPetPrice)) {
            if (numPetPrice > 0) {
                priceStr = `pet: ${formatPrice(getStringValue(petPrice))}`;
            }
        }

        if (!isNaN(numBreedPrice)) {
            if (numBreedPrice > 0) {
                priceStr = (priceStr.length > 0) ? priceStr + "/" : "" 
                priceStr = priceStr + `breed: ${formatPrice(getStringValue(breedPrice))}`;
            }
        }
        
        return priceStr;
    }

    const data: Parameter[] = [
        { name: "Кличка",           value: getStringValue(props.petCard.name)},
        { name: "Порода",           value: getBreed(props.petCard.breed)},
        { name: "Дата рождения",    value: formatDate(getStringValue(props.petCard.birthday))},
        { name: "Цена в любимцы",   value: getPriceString(props.petCard.pet_price, props.petCard.breed_price)}
    ]

    const cardText = data.map((item) => (
        (item.value !== '') ? <p key={item.name}>{item.value}</p> : null
    ));
    
    const buttonFavoriteJSX: JSX.Element =
        <>
            <div><button className="favorite_button">Поместить в избранное</button></div>
        </>
    
    const getValidImagePath = (pathFile: string | undefined) => {
        return pathFile && pathFile.trim() !== "" && pathFile.startsWith("http")
          ? pathFile
          : "/cats_default.png";
      };      
    
    const CatImage = ({ pathFile }: { pathFile: string }) => {
        const [imgSrc, setImgSrc] = useState<string>(getValidImagePath(pathFile));

        return (
          <img
            src={imgSrc}
            alt="Cat"
            onError={() => setImgSrc("/cats_default.png")} // Если ошибка загрузки → ставим картинку по умолчанию
            className="card_item_image"
          />
        );
    };
    
    
    //{(!props.petCard.photos || props.petCard.photos.length === 0) 
    //    ? <CatImage pathFile={ "" }/> 
    //    : <CatImage pathFile={ props.petCard.photos[0].path_file }/>
    //}

    
    // <img alt={props.petCard.photos[0].path_file} 
    //  src={ props.petCard.photos[0].path_file} 
    //  className="card_item_image"
    //  />


    return (
        <>
            <div className="card_item">
                <CatImage pathFile={ (!props.petCard.photos || props.petCard.photos.length === 0) ? "" : props.petCard.photos[0].path_file }/> 
                <div className="card_item_text">
                    {cardText}
                </div>

                <button className="button_more">Подробнее</button>
            </div>
        </>
    );
}

/*
{(userIsAuthenticated && props.isFavorite) ? buttonFavoriteJSX : <></>}  
<button className="button_more2">Подробнее2</button>

*/