import './App.css';
import Geography from './components/Geography';
import Language from './components/Language';
import Login from './components/Login';
import Logo from './components/Logo';

import { useDispatch, useSelector } from 'react-redux';
import ContentPage from './components/ContentPage';
import SectionSelector from './components/SectionSelector';
import { setUserLogin } from './redux/authUser';
import { useEffect, useState } from 'react';
import MainMenu from './components/MainMenu';
import Footer from './components/Footer';
import UploadPhoto from './components/UploadPhoto';

function AppDev(): JSX.Element {
  const dispatch = useDispatch();
  
  const { authenticatedMode } = useSelector((state: any) => state.authenticatedMode);
  const { debugMode } = useSelector((state: any) => state.debugMode);

  const { userNic, userIsAuthenticated } = useSelector((state: any) => state.user);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  function changeFavicon(src: string) {
    var link = document.createElement('link'),
        oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = src;
    if (oldLink) {
     document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
   }
  
  const empty: JSX.Element = 
    <>
      <div></div>
    </>
  
  const isVisibleBurgerMenu: boolean = false
  const isVisibleGeography: boolean = false
  const isVisibleSectionSelctor: boolean = false
  const isVisibleLanguage: boolean = false

  //changeFavicon("petbreeder_favicon.svg");
  //document.title = "Petbreeder";

  useEffect(() => {
    changeFavicon("petbreeder_favicon.png"); // Меняем favicon при загрузке
    document.title = "Petbreeder";
  }, []);

  return (
    <>
      <div className='main_screen'>
        <div className='header_bar'>
          <div className='status_bar'>
            <Logo />
            <Login />
          </div>
          
          <MainMenu />
        </div>

        <div className='screen'>
          <div className='screen_content'>
            <ContentPage />
            <div className="footer_common">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
/*



*/
// <ContentPage />
//<UploadPhoto />
//<input type='file'/>
export default AppDev;


/*

{(isVisibleGeography) ? <Geography /> : empty}
{(isVisibleSectionSelctor) ? <SectionSelector /> : empty}

{(isVisibleLanguage) ? <Language /> : empty}



  const burgerMenu: JSX.Element = 
    <>
      <div className='burger_menu' onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <span className={isMenuOpen ? "burger-icon open" : "burger-icon"}></span>
        <span className={isMenuOpen ? "burger-icon open" : "burger-icon"}></span>
        <span className={isMenuOpen ? "burger-icon open" : "burger-icon"}></span>
      </div>
    </>
  
  const burgerMenu: JSX.Element = 
    <>
      <div className='burger_menu'>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>

{(isVisibleBurgerMenu) ? burgerMenu : empty}

*/
