import { useState } from "react";
import { nurseryArr } from "../DebugData/DebugNurseryList";
import NurseryEdit from "./NurseryEdit";
import ObjectList from "../ObjectList";
import { useSelector } from "react-redux";
import { ObjectListTypeCard } from "../CommonData/DataTypes";

export type Nursery = {
    id: number;
    section: number;
    logoNursery: string;
    name: string;
    federation: string;
    logoFrederation:string;
    breeder: string;
    adress: string;
    country: string;
    club: string;
    clubURL: string;
    registrationDate: string;
    registrationNumber: string;
    validDate: string;
}

export default function MyNursery(): JSX.Element {
    const { userID, userNic, userIsAuthenticated } = useSelector((state: any) => state.user);
    
    const urlNursery: string = `https://mitrapro.tech/cat_nursery?user=${userID}`;
    const dataType: ObjectListTypeCard = "CatNursery";

    const noAccessJSX: JSX.Element = 
        <>
            <p>Для получения доступа к своим питомникам необходимо пройти авторизацию, 
                нажмите кнопку Войти в правом верхнем углу экрана.
            </p>
        </>

    return (
        <>  
            {(userIsAuthenticated) ? 
                <ObjectList url={urlNursery} dataType={dataType}
                    callFrom={"MyNursery"} catStatus={null} 
                    parentID={userID} isAppend={true} /> : noAccessJSX
            }
        </>
    );
}
