import { useParams } from "react-router-dom";
import ObjectList from "./ObjectList";
import { ObjectListTypeCard } from "./CommonData/DataTypes";

export type Event = {
    id: number;
    photo: string;
    date: string;
    title: string;
    description: string;
}

export default function NurseryPage(): JSX.Element {    
    //const [showNurseryWindowID, setShowNurseryWindowID] = useState<number>(0);
    //const [activeNurserySection, setActiveNurserySection] = useState<number>(1);

    /*useEffect(() => {
            requestNursery('https://mitrapro.tech/cats_withphoto?is_show_advertiser=true');
        }, []); // Пустой массив зависимостей указывает на выполнение только при монтировании*/
    const urlNursery: string = "https://mitrapro.tech/cat_nursery/";
    const dataType: ObjectListTypeCard = "CatNursery";

    const { id } = useParams<{ id: string }>(); // Получаем параметр id
    //const urlNursery: string = (id) ? `https://mitrapro.tech/cat_nursery/${id}` : "https://mitrapro.tech/cat_nursery/"

    return (
        <>
            <ObjectList url={urlNursery} dataType={dataType}
                callFrom={'NurseryPage'} catStatus={null} parentID={null} isAppend={false} />
            
        </>
    );
}

//<ObjectList url={urlNursery} dataType={dataType} />

// {(showNurseryWindowID > 0) ? nurseryWindowJSX : nurseryList}

/*

const nurseryWindowJSX: JSX.Element =
        <>
            <div className="nursery_window_overlay">
                <div className="nursery_window_content_common">
                    <button className="full_card_close_button"
                        onClick={() => setShowNurseryWindowID(0)}
                    >
                        <img src="close_button.png" alt="Left"/>
                    </button>
                    
                    { (showNurseryWindowID === 1) ? nurseryJSX : 
                        <div>Для питомника {getNurseryName(showNurseryWindowID)} нет данных</div>
                    }
                </div>
            </div>
        </>

*/

/*

    const nurseryJSX: JSX.Element = 
        <>
            <div className="nursery_box">
                <h1>Питомник {getNurseryName(showNurseryWindowID)}</h1>
                <p>Описание питомника</p>
                {nurserySectionJSX}
            </div>
        </>
*/

/*
    function getNurseryName(id:number): string {
        for (let i = 0; i <= nurseryArr.length - 1; i++) {
            if (nurseryArr[i].id === id) {return nurseryArr[i].name}
        }
        return '';
    }
*/

/*
    const nurseryList = nurseryArr.map((item: Nursery) =>
        <div className="nursery">
            <div key={item.id} 
                onClick={() => handlerClick(item.id)}
            >
                <table className="styled-table">
                    <tbody>
                        <tr className="row">
                            <td className="cell1">{'Питомник: '}</td>
                            <td className="cell2">{item.name}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Клуб: '}</td>
                            <td className="cell2">{item.club}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Заводчик: '}</td>
                            <td className="cell2">{item.breeder}</td>
                        </tr>
                        <tr className="row">
                            <td className="cell1">{'Город: '}</td>
                            <td className="cell2">{item.adress}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
*/

/*
const handlerClick = (item: number) => {
        setShowNurseryWindowID(item);
    }
*/

/*
const nurserySectionJSX = nurserySectionArray.map((item) =>
        (!item.isEmpty) &&
            <>
                <div className="nursery_section" 
                    key={item.id} 
                    onClick= {() => setActiveNurserySection((activeNurserySection === item.id) ? 0 : item.id)}
                >

                    <div className="nursery_section_head">
                        <h2>{item.name}</h2>
                    </div>
                    <div className="nursery_section_button">
                        <h2>{(activeNurserySection === item.id) ?  "\xD7" : "\x2B"}</h2>
                    </div>
                </div>
                {(activeNurserySection === item.id) ? 
                    <div className="nursery_section_card">
                        <ObjectList url={item.url} dataType={item.dataType} />
                    </div> : <></>
                }
            </>
    );
*/

/*
    const nurserySectionArray = [
        {
            id: 1,
            name: "Производители",
            component: breedingAnimalListJSX,
            isEmpty: false,
            url: "https://mitrapro.tech/cat_nurseries/1/BREEDING/cats",
            dataType: "CatCard",
        },
        {
            id: 2,
            name: "Котята",
            component: kittenListJSX,
            isEmpty: false,
            url: "https://mitrapro.tech/cat_nurseries/1/KITTEN/cats",
            dataType: "CatCard",
        },
        {
            id: 3,
            name: "Ньютеры",
            component: neuterListJSX,
            isEmpty: false,
            url: "https://mitrapro.tech/cat_nurseries/1/NEUTER/cats",
            dataType: "CatCard",
        },
        {
            id: 4,
            name: "Ветераны",
            component: veteranListJSX,
            isEmpty: false,
            url: "https://mitrapro.tech/cat_nurseries/1/VETERAN/cats",
            dataType: "CatCard",
        },
        {
            id: 5,
            name: "Достижения",
            component: achievementsJSX,
            isEmpty: false,
            url: "https://mitrapro.tech/cat_nurseries/1/achievement-photos/",
            dataType: "CatPhoto",
        },
        {
            id: 6,
            name: "Фото",
            component: photosJSX,
            isEmpty: false,
            url: "https://mitrapro.tech/cat_nurseries/1/photos/",
            dataType: "CatPhoto",
        }, 
    ]
*/