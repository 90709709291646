import TelephoneNumberHidenAdmin from "./TelephoneNumberHidenAdmin";

export default function ContactPage(): JSX.Element {
    return (
        <>
            <div className="contact_container">
                <h2>{'Контакты'}</h2>
                <div className="contact_item">
                    <img className="contact_item_image" alt="" src="/telephone_number.svg" />
                
                    <TelephoneNumberHidenAdmin telNum={"+7 (918) 46-33-009;"} 
                        telNumHiden={"+7 XXX XX-XX-XXX;"} 
                        className={"contact_item_text"} />
                    <TelephoneNumberHidenAdmin telNum={"+7 (989) 80-80-125."} 
                        telNumHiden={"+7 XXX XX-XX-XXX."} 
                        className={"contact_item_text"} />
                </div>
                <div className="contact_item">
                    <img className="contact_item_image" alt="" src="/Frame_155.svg" />
                    <div className="contact_item_text">
                        <a href="mailto:admin@petbreeder.pro" className="send_mail">{"admin@petbreeder.pro"}</a>
                    </div>
                </div>
            </div>
        </>
    );
}

