import { ObjectCatBreed, ObjectCatSaleStatus, ObjectCatSex, ObjectCatStatus } from "./DataTypes";

export const baseUrl: string = "http://localhost:8000"
//export const baseUrl: string = "https://mitrapro.tech"

export const widtMobileDevice: number = 669;
export const paginationServer: number = 20;

export const catFederationDictionary = ["", "WCF", "FIFe", "МФА", "FARUS", "ICU"];


type CatBreedDict = {
    code: ObjectCatBreed,
    rus: string,
}

export const catBreedDictionary: CatBreedDict[] = [
    { code: "MONGREL",  rus: "Беспородный(-ая)" },
    { code: "ABY",      rus: "Абессинская" },    
    { code: "ABT",      rus: "Американский бобтейл" },
    { code: "ASH",      rus: "Американская короткошерстная" },
    { code: "ACL",      rus: "Американский керл длинношерстный" },
    { code: "ACS",      rus: "Американский керл короткошерстный" },
    { code: "BAL",      rus: "Балинезийская" },
    { code: "BEN",      rus: "Бенгальская" },
    { code: "BML",      rus: "Бурмилла" },
    { code: "BRI",      rus: "Британская короткошерстная" },
    { code: "BUR",      rus: "Бурма" },
    { code: "CHA",      rus: "Картезианская" },
    { code: "CRX",      rus: "Корниш-рекс" },
    { code: "DRX",      rus: "Девон-рекс" },
    { code: "DSX",      rus: "Донской сфинкс" },
    { code: "EUR",      rus: "Европейская короткошерстная" },
    { code: "EXO",      rus: "Экзотическая короткошерстная" },
    { code: "GRX",      rus: "Герман-рекс" },
    { code: "HVB",      rus: "Гавана" },
    { code: "JAV",      rus: "Яванезийская" },
    { code: "JBT",      rus: "Японский бобтейл" },
    { code: "KBL",      rus: "Курильский бобтейл длинношерстный" },
    { code: "KBS",      rus: "Курильский бобтейл короткошерстный" },
    { code: "KOR",      rus: "Корат" },
    { code: "MAN",      rus: "Мэнкс" },
    { code: "MAU",      rus: "Мау египетская" },
    { code: "MCO",      rus: "Мейн-кун" },
    { code: "MUN",      rus: "Манчкин" },
    { code: "NFO",      rus: "Норвежская лесная" },
    { code: "NEV",      rus: "Невская маскарадная" },
    { code: "NIB",      rus: "Нибелунг" },
    { code: "OCI",      rus: "Оцикет" },
    { code: "ORL",      rus: "Ориентальная длинношерстная" },
    { code: "ORS",      rus: "Ориентальная короткошерстная" },
    { code: "PER",      rus: "Персидская" },
    { code: "PBD",      rus: "Питерболд" },
    { code: "RUS",      rus: "Русская голубая" },
    { code: "RAG",      rus: "Рэгдолл" },
    { code: "SIA",      rus: "Сиамская" },
    { code: "SIB",      rus: "Сибирская" },
    { code: "SNO",      rus: "Сноу-шу" },
    { code: "SOK",      rus: "Сококе" },
    { code: "SOM",      rus: "Сомалийская" },
    { code: "SPX",      rus: "Канадский сфинкс" },
    { code: "SIN",      rus: "Сингапура" },
    { code: "SRX",      rus: "Селкирк-рекс" },
    { code: "SBI",      rus: "Священная бирма" },
    { code: "SFS",      rus: "Скоттиш-фолд короткошерстный" },
    { code: "SFL",      rus: "Скоттиш-фолд длинношерстный" },
    { code: "SCS",      rus: "Страйт короткошерстный" },
    { code: "SCL",      rus: "Страйт длинношерстный" },
    { code: "TBT",      rus: "Тайский бобтейл" },
    { code: "THA",      rus: "Тайская" },
    { code: "TIF",      rus: "Тиффани" },
    { code: "TON",      rus: "Тонкинская" },
    { code: "TUA",      rus: "Турецкая ангора" },
    { code: "TUV",      rus: "Турецкий ван" },
    { code: "URX",      rus: "Уральский рекс" },
];

type CatSaleStatusDict = {
    en: ObjectCatSaleStatus,
    rus: string,
}

export const catSaleStatusDictionary: CatSaleStatusDict[] = [
    { en: "NOT_SALE", rus: "Не для продажи" },
    { en: "RESERVED", rus: "Зарезервирован" },
    { en: "SALE", rus: "Продается" },
    { en: "SOLD", rus: "Продан" },
];

type CatSexDict = {
    en: ObjectCatSex,
    rus: string,
}

export const catSexDictionary: CatSexDict[] = [
    { en: "MALE", rus: "Кот" },
    { en: "FEMALE", rus: "Кошка" },
];

type CatStatusDict = {
    en: ObjectCatStatus,
    rus: string,
}

export const catStatusDictionary: CatStatusDict[] = [
    { en: "BREEDING", rus: "Племенное животное" },
    { en: "NEUTER", rus: "Ньютер" },
    { en: "VETERAN", rus: "Ветеран" },
    { en: "KITTEN", rus: "Котёнок" },
    { en: "GRADUATE", rus: "Выпускник" },
];
