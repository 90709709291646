import React, { useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

interface UploadPhotoProps {
    catPhotoID: number;
    lockInputFile: boolean;
    onUploadPhoto: (catPhotoID:number, updatedPathFile: string) => void | null;
}

export default function  UploadPhoto(props: UploadPhotoProps): JSX.Element {
    const { userID, userNic, userLogin, userIsAuthenticated, userToken, userPhoneNumber } = useSelector((state: any) => state.user);
    
    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState("");
    const [photoUrl, setPhotoUrl] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    
    const handleButtonClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();  // Программно активируем input[type="file"]
        }
      };
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            alert("Выберите файл!");
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await axios.post(`https://mitrapro.tech/upload-cat-photo/${props.catPhotoID}/`, formData, {
                headers: {
                    'Content-Type': "multipart/form-data",
                    'Authorization': `Token ${userToken}`, // Авторизация
                },
            });

            if (response.data.success) {
                setMessage("Файл загружен!");
                setPhotoUrl(response.data.path_file);
                props.onUploadPhoto(props.catPhotoID, response.data.path_file);
            } else {
                setMessage(response.data.message);
            }
        } catch (error) {
            setMessage("Ошибка загрузки файла" + error);
        } finally {
            setUploading(false);
        }
    };

    return (
        <>
            <p>Место для фотографии.</p>
            <button onClick={handleButtonClick} disabled={props.lockInputFile}>Выбрать файл</button>
            <div>{(file !== null ? file.name : "Файл не выбран")}</div>
            <input ref={fileInputRef} type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />
            <button onClick={handleUpload} disabled={uploading || props.lockInputFile || file === null}>
                {uploading ? "Загрузка..." : "Загрузить"}
            </button>
            <p>{message}</p>
            {photoUrl}
        </>
    );
};

//<div>{file?.name}</div>
/*
{photoUrl && <img src={photoUrl} alt="Uploaded" width="200" />}
*/
