import { click } from "@testing-library/user-event/dist/click";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserLogin } from "../../redux/authUser";
import { User } from "../CommonData/DataTypes";

export default function NickName(): JSX.Element {
    const dispatch = useDispatch();
    // Функция задержки (debounce)
    const useDebounce = (value: string, delay: number) => {
        const [debouncedValue, setDebouncedValue] = useState(value);
  
        useEffect(() => {
            const handler = setTimeout(() => {
            setDebouncedValue(value);
            }, delay);
  
            return () => clearTimeout(handler);
        }, [value, delay]);
  
    return debouncedValue;
    };

    const { userID, userNic, userLogin, userIsAuthenticated, userToken, userPhoneNumber } = useSelector((state: any) => state.user);
    
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [serverMessage, setServerMessage] = useState<string | null>(null);
    const [isServerMessageVisible, setIsServerMessageVisible] = useState<boolean>(false);

    const[isEditMode, setEditMode] = useState<boolean>(false);

    const [nickname, setNickname] = useState<string>(userNic);
    const [isServerValidationNickname, setIsServerValidationNickname] = useState<boolean>(true);

    const debouncedNickname = useDebounce(nickname, 500); // Ждем 500 мс перед запросом

    const isValidNickname = (nickname: string) => {
        return nickname.length >= 3 && !nickname.includes("@")  
                && !nickname.includes(" ") && /^[a-zA-Zа-яА-Я0-9\-_\.]+$/.test(nickname);
    };
    
    // Функция для проверки никнейма на сервере
    const checkNicknameByServer = async (nickname: string) => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);

        const request = {
            nickname: nickname
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        fetch("https://mitrapro.tech/find_nickname/", requestOptions)
            .then((response) => {
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                setServerMessage(data["message"] as string);
                setIsServerValidationNickname(data["success"] as boolean);
                if (!data["success"]) setIsServerMessageVisible(true);
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };

    const saveNickNameOnTheServer = () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);

        let user: User = { 
            userID: userID,
            userNic: userNic,
            userLogin: userLogin,
            userToken: userToken,
            userIsAuthenticated: userIsAuthenticated,
            userPhoneNumber: userPhoneNumber,
        };

        const request = {
            user_id: userID,
            nickname: nickname
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' ,
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch("https://mitrapro.tech/set_nickname/", requestOptions)
            .then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    setServerMessage(data["message"]);
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                //return response.json();
                return data;
            })
            .then(data => {
                setServerMessage(data["message"] as string);
                setIsServerValidationNickname(data["success"] as boolean);
                if (data["success"] as boolean) {
                    localStorage.setItem('nicName', nickname);
                    user["userNic"] = nickname;

                    setUserLogin (dispatch) (user);
                } else {
                    setIsServerMessageVisible(true);
                }
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
                setNickname(userNic);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });

    };

    useEffect(() => {
        if (isValidNickname(debouncedNickname) && userNic !== debouncedNickname) {
            checkNicknameByServer(debouncedNickname);
        } 
    }, [debouncedNickname]);
    
    const onChangeNickname = (nickname: string) => {
        setNickname(nickname);
    }

    const handlerClickEditButton = () => {
        setEditMode(true);
    }

    const handlerClickSaveButton = () => {
        saveNickNameOnTheServer();
        setEditMode(false);
    }

    const handlerClickCancelButton = () => {
        setEditMode(false);
        setNickname(userNic);
        setIsServerValidationNickname(true);
        setServerMessage(null);
        setIsServerMessageVisible(false);
        setError(null);
    }

    const handlerClickDeleteButton = () => {
        setEditMode(false);
    }

    const handlerClickAppendButton = () => {}

    const editButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickEditButton} disabled={isEditMode}>
                <img src={"/edit_button.png"} alt={"E"} />
            </button>
        </>
    
    const saveButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' 
                onClick={handlerClickSaveButton} 
                disabled={(!isEditMode || !isValidNickname(nickname) || loading || !isServerValidationNickname)}>
                
                {loading ? 
                    <span className="loader"></span> : 
                        <img src={"/save_button.png"} alt={"S"} />
                }
            
            </button>
        </>
    
    const cancelButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickCancelButton} disabled={!isEditMode}>
                <img src={"/cancel_button.png"} alt={"C"} />
            </button>
        </>
    
    const deleteButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickDeleteButton} disabled={!isEditMode}>
                <img src={"/delete_button.png"} alt={"D"} />
            </button>
        </>
    
    const appendButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickAppendButton} disabled={!isEditMode}>
                <img src={"/append_button.png"} alt={"D"} />
            </button>
        </>
    
    const isShowDeleteButton: boolean = false;
    const isShowAppendButton: boolean = false;

    const escdButtonBoxJSX: JSX.Element = 
        <>
            {editButtonJSX}
            {saveButtonJSX}
            {cancelButtonJSX}
            {(isShowDeleteButton) && deleteButtonJSX}
            {(isShowAppendButton) && appendButtonJSX}
        </>

    return (
        <>
            <div className="nickname" >
                <label className="nickname_label" htmlFor="nicknameInput">
                        {"НИК (nickname, псевдоним):"}
                </label>
                <div className="nickname_input_box">
                    <input
                        className={(!isEditMode || isValidNickname(nickname) && isServerValidationNickname) 
                                        ? "nickname_input_fld" 
                                            : "nickname_input_fld_invalid"}
                        placeholder="НИК"
                        required
                        type="text"
                        name="nickname" 
                        maxLength={32}
                        id="nicknameInput"
                        disabled={!isEditMode}
                        autoComplete="off"
                        value={nickname}
                        onChange={(e) => onChangeNickname(e.target.value)}
                    />
                    {escdButtonBoxJSX}
                </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            {isServerMessageVisible && <p className="error-message">{serverMessage}</p>}
            {isEditMode && <p>Внимание! НИК може содержать только буквы и цифры</p>}
        </>
    )
}

/*

value={userNic}

*/