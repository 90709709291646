import { MutableRefObject, useRef, useState } from "react";
//import QRCode from "qrcode.react";
import { QRCode } from "react-qrcode-logo";

interface CopyToClipboardProps {
    url: string;
}

export default function QRcodeURL(props: CopyToClipboardProps): JSX.Element {
    const [url] = useState(props.url);
    const [copied, setCopied] = useState<boolean>(false);
    const qrRef = useRef<QRCode | null>(null);


    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(url);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Убираем сообщение через 2 сек
        } catch (err) {
            console.error("Ошибка копирования", err);
        }
    };

    const handleSaveQR = () => {
        qrRef.current?.download("png", "qr");
    };
    
    return (
        <>
            <div className="copy_container">
                <input type="text" value={url} readOnly className="copy_input" />
                <button onClick={handleCopy} className="copy_button">
                    <div className="copy_pictogram">
                        <img src={copied ? "/tick.png": "/copy_button.png"} alt={"C"} />
                        {copied ? "Ссылка на питомник скопированна!" : "Копировать ссылку на питомник"}
                    </div>
                </button>
    
                <QRCode value={url} size={200} ref={qrRef as MutableRefObject<QRCode>}/>
                <button onClick={handleSaveQR} className="copy_button">
                    <div className="copy_pictogram">
                        <img src={"/download_button.png"} alt={"D"} />
                        {"Сачать QR-код"}
                    </div>
                </button>
            </div>
        </>
    );
};