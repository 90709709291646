export default function Footer(): JSX.Element {
    return (
        <>  
            <div className="footer_line"></div>
            <div className='footer_text'>      
                <p>&copy; 
                    <a href="https://www.mitra123.pro/" 
                        target="_blank" rel="noreferrer" 
                        className='creator_href'>{' ООО "Митра Про 123"'}
                    </a>
                    {", 2024."}
                </p>
                <p>{"Разработка web-приложений. Всё просто как 1, 2, 3."}</p>
            </div>
        </>
    );
}