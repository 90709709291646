import TelephoneNumberHidenAdmin from "./TelephoneNumberHidenAdmin";

export default function AboutProjectPage(): JSX.Element {
    return (
        <>      
        <div className="about_page">
            <h3>Petbreeder — объединяем сердца заводчиков и любителей животных!</h3>
            <p>Создайте профиль для вашего питомника, продавайте котят и продвигайте своих племенных животных с помощью Petbreeder</p>
            <h4>Преимущества Petbreeder:</h4>
            <ul itemType="CIRCLE">
                <li><strong>Узкая специализация:</strong> 
                    <p>Petbreeder полностью посвящён кошкам, что позволяет глубже удовлетворять потребности заводчиков и покупателей.</p>
                </li>
                <li><strong>Удобство размещения:</strong> 
                    <p>Простой и понятный интерфейс для публикации объявлений о продаже животных и информации о питомнике.</p>
                </li>
                <li><strong>Поддержка профессионалов:</strong> 
                    <p>Специальные инструменты для заводчиков, включая возможность демонстрации достижений и титулов.</p>
                </li>
                <li><strong>Единая платформа:</strong> 
                    <p>Возможность продавать не только животных, но и сопутствующие товары и услуги для питомцев.</p>
                </li>
                <li><strong>Активное продвижение:</strong> 
                    <p>Регулярная реклама портала для привлечения целевых покупателей.</p>

                </li>
                <li><strong>Сообщество единомышленников:</strong> 
                    <p>Возможность общения и обмена опытом между заводчиками и любителями животных.</p>
                </li>
            </ul>           
            
            <p>{"Для пользователей Petbreeder создан Telegram-канал "} 
                <a href="https://t.me/petbreederpro" 
                        target="_blank" rel="noreferrer" 
                        className='creator_href'>{'@petbreederpro'}
                </a>
                {". Следите за новостями и улучшениями платформы."}
            </p>
            

            <p>{"Приглашаем к сотрудничеству "} <strong>{"рекламодателей."}</strong></p>

            <p>{"Приглашаем к участию в проекте "} <strong>{"инвесторов."}</strong></p>
            
            <p>Для неравнодушных желающих поддержать проект донатами 
                сообщаем банковские реквизиты для перевода денег:
            </p>
        
            <p>ООО "МИТРА", ИНН 2361013428, КПП 231201001, ОГРН 1152361013034,
                расчётный счёт 40702810030000042944 в Краснодарском отделении N 8619 ПАО СБЕРБАНК, 
                БИК 040349602 корреспондентский счет 30101810100000000602
            </p>
            
            <div className="about_page_img">
                <p>{"В приложении Сбер Онлайн сканируйте QR-код:"}</p>
                <img src="/QR-SberMitra.jpeg" />
                <p>{"и делайте перевод для поддержки проекта."}</p>
            </div>

            <p>{"По всем вопросам обращаться: "}
                <a href="mailto:admin@petbreeder.pro" className="send_mail">{"admin@petbreeder.pro"}</a>
                <TelephoneNumberHidenAdmin telNum={"+7 (918) 46-33-009;"} 
                        telNumHiden={"+7 XXX XX-XX-XXX;"} 
                        className={"contact_item_text"} />
                <TelephoneNumberHidenAdmin telNum={"+7 (989) 80-80-125."} 
                        telNumHiden={"+7 XXX XX-XX-XXX."} 
                        className={"contact_item_text"} />
            </p>
        </div>
        </>
    );
};