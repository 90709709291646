import React, { ReactNode, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { widtMobileDevice } from "./CommonData/Constants";

export default function MainMenu(): JSX.Element {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= widtMobileDevice); // было 768
    const location = useLocation(); // Отслеживаем изменение маршрута

    // Закрываем меню при изменении маршрута
    useEffect(() => {
      setIsMenuOpen(false);
    }, [location.pathname]);

    // Функция для отслеживания ширины экрана
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= widtMobileDevice); // было 768
            if (window.innerWidth > widtMobileDevice) {
                setIsMenuOpen(false); // Закрываем меню при увеличении экрана
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const menuOptionsJSX: React.ReactNode = 
        <>
            <NavLink className='menu_item' to="/" onClick={() => setIsMenuOpen(false)}>Главная</NavLink>
            <NavLink className='menu_item' to="/nursery" onClick={() => setIsMenuOpen(false)}>{"Питомники (Заводчики)"}</NavLink>
            <NavLink className='menu_item' to="/about_project" onClick={() => setIsMenuOpen(false)}>О проекте</NavLink>
            <NavLink className='menu_item' to="/contacts" onClick={() => setIsMenuOpen(false)}>Контакты</NavLink>
        </>

    const desktopMenuJSX: React.ReactNode = 
        <>
            <div className='main_menu'>
                {menuOptionsJSX}
            </div>
        </>
    
    const mobileMenuJSX: React.ReactNode = 
        <>
            <button className="burger_main_menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                {isMenuOpen ? "✖" : "☰"}
            </button>
            { isMenuOpen &&
                    <div className='mobile_menu'>
                        {menuOptionsJSX}
                    </div>
            }
        </>

    return (
        <>
            { !isMobile ? desktopMenuJSX : mobileMenuJSX }   
        </>
    );
}

/*
            <NavLink className='menu_item' to="/pet_food">Корма</NavLink>
            <NavLink className='menu_item' to="/animal_care">Уход</NavLink>
            <NavLink className='menu_item' to="/grooming">Грумминг</NavLink>
            <NavLink className='menu_item' to="/expo">Выставки</NavLink>
            <NavLink className='menu_item' to="/club">Клубы</NavLink>
            <NavLink className='menu_item' to="/shelter">Приюты</NavLink>
            <NavLink className='menu_item' to="/hotel">Гостиницы</NavLink>
            <NavLink className='menu_item' to="/vet">Ветеринария</NavLink>
            <NavLink className='menu_item' to="/carrier">Перевозчики</NavLink>
            <NavLink className='menu_item' to="/vacancy">Вакансии</NavLink>


*/