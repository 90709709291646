import { ReactNode, useRef, useState } from "react";
import TelephoneNumberHiden from "./TelephoneNumberHiden";
import { CatCard, CatPhoto, ObjectCatBreed, ObjectCatSaleStatus, ObjectCatSex, ObjectCatStatus, ObjectListFrom, Parameter } from "./CommonData/DataTypes";
import { formatDate, formatPrice, getBreed, getSex, getStringValue } from "./lib/FormatData";
import { useSelector } from "react-redux";
import { catBreedDictionary, catSaleStatusDictionary, catSexDictionary } from "./CommonData/Constants";
import UploadPhoto from "./UploadPhoto";

interface CatFullCardProps {
    catCard: CatCard;
    indexArrayCards: number;
    callFrom: ObjectListFrom;
    onUpdate: (updatedItem: CatCard) => void | null;
    onUpdatePhoto: (updatedItem: CatPhoto) => void | null;
    onLockNavButton: (lockNavButton: boolean) => void | null;
    onAppendPhoto: (appendedItem: CatPhoto) => void | null;
    onMakeCatByFirst: () => void | null;
    onMakePhotoByFirst: (firstPhotoID: number) => void | null;
    onDeletePhoto: (photoID: number) => void | null;
}

export default function CatFullCard(props: CatFullCardProps): JSX.Element {
    const { userToken } = useSelector((state: any) => state.user);
    
    //Состояния
    const [activePhoto, setActivePhoto] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [serverMessage, setServerMessage] = useState<string | null>(null);
    const [isServerMessageVisible, setIsServerMessageVisible] = useState<boolean>(false);

    const[isEditModeText, setEditModeText] = useState<boolean>(false);
    const[indexEditModePhoto, setIndexEditModePhoto] = useState<number | null>(null);

    //const [catPhotos, setCatPhotos] = useState<Array<CatPhoto>>(props.catCard.photos);

    const [catStatus, setCatStatus] = useState<ObjectCatStatus>(props.catCard.cat_status);
    const [catBreed, setCatBreed] = useState<ObjectCatBreed>(props.catCard.breed);
    const [catName, setCatName] = useState<string>(props.catCard.name);
    const [catSirID, setCatSirID] = useState<number>(props.catCard.sir_id);
    const [catSirName, setCatSirName] = useState<string>(props.catCard.sir_name);
    const [catSirCoat, setCatSirCoat] = useState<string>(props.catCard.sir_coat);
    const [catDamID, setCatDamID] = useState<number>(props.catCard.dam_id);
    const [catDamName, setCatDamName] = useState<string>(props.catCard.dam_name);
    const [catDamCoat, setCatDamCoat] = useState<string>(props.catCard.dam_coat);
    const [catBirthday, setCatBirthday] = useState<string | null>(props.catCard.birthday);
    const [catPetPrice, setCatPetPrice] = useState<string | null>(props.catCard.pet_price);
    const [catBreedPrice, setCatBreedPrice] = useState<string | null>(props.catCard.breed_price);
    const [catCoat, setCatCoat] = useState<string>(props.catCard.coat);
    const [catSex, setCatSex] = useState<ObjectCatSex>(props.catCard.sex);
    const [catSaleStatus, setCatSaleStatus] = useState<ObjectCatSaleStatus>(props.catCard.sale_status);
    const [catIsNeuter, setCatIsNeuter] = useState<boolean>(props.catCard.is_neuter);
    const [catAdvText, setCatAdvText] = useState<string>(props.catCard.adv_text);
    const [catIsShowAdvertiser, setCatIsIShowAdvertiser] = useState<boolean>(props.catCard.is_show_advertiser);

    const [photoID, setPhotoID] = useState<number | null | undefined>(props.catCard.photos?.[0]?.id);
    const [photoCat, setPhotoCat] = useState<number | null | undefined>(props.catCard.photos?.[0]?.cat);
    const [photoPathFile, setPhotoPathFile] = useState<string | null | undefined>(props.catCard.photos?.[0]?.path_file);
    const [photoDate, setPhotoDate] = useState<string | null | undefined>(props.catCard.photos?.[0]?.date);
    const [photoTitle, setPhotoTitle] = useState<string | null | undefined>(props.catCard.photos?.[0]?.title);
    const [photoDescription, setPhotoDescription] = useState<string | null | undefined>(props.catCard.photos?.[0]?.description);
    const [photoIsShowAchivement, setPhotoIsShowAchivement] = useState<boolean | null | undefined>(props.catCard.photos?.[0]?.is_show_achivement);
    const [photoIsShowPhoto, setPhotoIsShowPhoto] = useState<boolean | null | undefined>(props.catCard.photos?.[0]?.is_show_photo);

    const [photoIDInit, setPhotoIDInit] = useState<number | null | undefined>(props.catCard.photos?.[0]?.id);
    const [photoCatInit, setPhotoCatInit] = useState<number | null | undefined>(props.catCard.photos?.[0]?.cat);
    const [photoPathFileInit, setPhotoPathFileInit] = useState<string | null | undefined>(props.catCard.photos?.[0]?.path_file);
    const [photoDateInit, setPhotoDateInit] = useState<string | null | undefined>(props.catCard.photos?.[0]?.date);
    const [photoTitleInit, setPhotoTitleInit] = useState<string | null | undefined>(props.catCard.photos?.[0]?.title);
    const [photoDescriptionInit, setPhotoDescriptionInit] = useState<string | null | undefined>(props.catCard.photos?.[0]?.description);
    const [photoIsShowAchivementInit, setPhotoIsShowAchivementInit] = useState<boolean | null | undefined>(props.catCard.photos?.[0]?.is_show_achivement);
    const [photoIsShowPhotoInit, setPhotoIsShowPhotoInit] = useState<boolean | null | undefined>(props.catCard.photos?.[0]?.is_show_photo);
    
    const isChangePhoto = (): boolean => {
        return (
            photoID !== photoIDInit || 
            photoPathFile !== photoPathFileInit ||
            photoDate !== photoDateInit ||
            photoTitle !== photoTitleInit ||
            photoDescription !== photoDescriptionInit ||
            photoIsShowAchivement !== photoIsShowAchivementInit ||
            photoIsShowPhoto !== photoIsShowPhotoInit
        );
    }

    const isChangeCat = (): boolean => {
        return (
            catStatus !== props.catCard.cat_status ||
            catBreed !== props.catCard.breed ||
            catName !== props.catCard.name ||
            catSirID !== props.catCard.sir_id ||
            catSirName !== props.catCard.sir_name ||
            catSirCoat !== props.catCard.sir_coat ||
            catDamID !== props.catCard.dam_id ||
            catDamName !== props.catCard.dam_name ||
            catDamCoat !== props.catCard.dam_coat ||
            catBirthday !== props.catCard.birthday ||
            catPetPrice !== props.catCard.pet_price ||
            catBreedPrice !== props.catCard.breed_price ||
            catCoat !== props.catCard.coat ||
            catSex !== props.catCard.sex || 
            catSaleStatus !== props.catCard.sale_status ||
            catIsNeuter !== props.catCard.is_neuter || 
            catAdvText !== props.catCard.adv_text ||
            catIsShowAdvertiser !== props.catCard.is_show_advertiser
        );
    }

    function getStatus(statusEng: string): string {
        let strStatus = ""

        for (let i = 0; i < catSaleStatusDictionary.length; i++) {
            if (statusEng === catSaleStatusDictionary[i].en) {
                strStatus = catSaleStatusDictionary[i].rus
                break
            }
        }
        
        return strStatus;
    }

    function isPhotoComment(arr: Parameter[]): boolean {
        let result: boolean = false;
        for (let i = 1; i < arr.length; i++) {
            if (typeof arr[i].value === 'string') {
                if (arr[i].value !== '') {
                    result = true;
                    break;
                }
            }
            
        }
        return result;
    }

    function isPrice(price: string | null): boolean {
        if (!price) return false;
        const numericValue = parseFloat(price);
        if (isNaN(numericValue) || numericValue === 0) {
            return false;
        } else {
            return true;
        }
    }

    const handlePhotoPathFileUpdate = (updatedCatPhotoID: number, newPathFile: string) => {
        let newPhoto: CatPhoto = {
            id: 0,
            cat: 0,
            path_file: "",
            date: "",
            title: "",
            description: "",
            is_show_achivement: false,
            is_show_photo: false,
        }

        for (let i=0; i<props.catCard.photos.length; i++) {
            if (props.catCard.photos[i].id === updatedCatPhotoID) {
                newPhoto = {
                    id: props.catCard.photos[i].id,
                    cat: props.catCard.photos[i].cat,
                    path_file: newPathFile,
                    date: props.catCard.photos[i].date,
                    title: props.catCard.photos[i].title,
                    description: props.catCard.photos[i].description,
                    is_show_achivement: props.catCard.photos[i].is_show_achivement,
                    is_show_photo: props.catCard.photos[i].is_show_photo,
                }
            }
        }
        
        props.onUpdatePhoto(newPhoto);

    };

    const catNameInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Кличка"
                required
                type="text"
                name="catName" 
                maxLength={64}
                id="catNameInput"
                autoComplete="on"
                value={catName}
                onChange={(e) => setCatName(e.target.value)}
            />
        </>
    
    const catBreedArrayJSX: React.ReactNode = catBreedDictionary.map((item) =>(
        <>
            <option value={item.code}>{item.rus}</option>
        </>)
    );

    const catBreedInputJSX: React.ReactNode = 
        <>
            <select className="selection_input_fld"
                id="catBreedInput"
                value={catBreed}
                onChange={(e) => setCatBreed(e.target.value as ObjectCatBreed)}
            >

                {catBreedArrayJSX}

            </select>
        </>
//        <>
//            <input
//                className={"cat_input_fld"}
//                placeholder="Порода"
//                required
//                type="text"
//                name="catBreed" 
//                maxLength={16}
//                id="catBreedInput"
//                autoComplete="on"
//                value={catBreed}
//                onChange={(e) => setCatBirthday(e.target.value)}
//            />
//        </>
    
    const catSirNameJSX: JSX.Element = <></>
    const catSirCoatJSX: JSX.Element = <></>
    const catDamNameJSX: JSX.Element = <></>
    const catDamCoatJSX: JSX.Element = <></>

    const catCoatInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Окрас"
                required
                type="text"
                name="catCoad" 
                maxLength={16}
                id="catCoatInput"
                autoComplete="on"
                value={catCoat}
                onChange={(e) => setCatCoat(e.target.value)}
            />  
        </>
    
    const catSexArrayJSX: React.ReactNode = catSexDictionary.map((item) =>(
        <>
            <option value={item.en}>{item.rus}</option>
        </>)
    );

    const catSexInputJSX: React.ReactNode = 
        <>
            <select className="selection_input_fld"
                id="catSaleStatusInput"
                value={catSex}
                onChange={(e) => setCatSex(e.target.value as ObjectCatSex)}
            >

                {catSexArrayJSX}

            </select>
        </>
    
    const catBirthdayInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Дата рождения"
                type="date"
                name="catBirthday" 
                maxLength={10}
                id="catBirthdayInput"
                value={catBirthday || ''}
                onChange={(e) => setCatBirthday(e.target.value)}
            />  
        </>
    
    const catPetPriceInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Цена в любимцы"
                required
                type="number"
                name="catPetPriceInput" 
                maxLength={12}
                id="catPetPriceInput"
                autoComplete="on"
                value={catPetPrice || ''}
                onChange={(e) => setCatPetPrice(e.target.value)}
            />  
        </>
    
    const catBreedPriceInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Цена для племенной работы"
                required
                type="number"
                name="catBreedPriceInput" 
                maxLength={12}
                id="catBreedPriceInput"
                autoComplete="on"
                value={catBreedPrice || ''}
                onChange={(e) => setCatBreedPrice(e.target.value)}
            />  
        </>
    
    const catSaleStatusArrayJSX: React.ReactNode = catSaleStatusDictionary.map((item) =>(
        <>
            <option value={item.en}>{item.rus}</option>
        </>)
    );

    const catSaleStatusInputJSX: React.ReactNode = 
        <>
            <select className="selection_input_fld"
                id="catSaleStatusInput"
                value={catSaleStatus}
                onChange={(e) => setCatSaleStatus(e.target.value as ObjectCatSaleStatus)}
            >

                {catSaleStatusArrayJSX}

            </select>
        </>
    
    const catAdvTextInputJSX: JSX.Element = 
        <>
            <textarea
                className={"cat_description_input_fld"}
                placeholder="Описание"
                required
                name="catAdvText" 
                maxLength={256}
                id="catAdvTextInput"
                autoComplete="on"
                value={catAdvText}
                onChange={(e) => setCatAdvText(e.target.value)}
            />
            
        </>
    
    const catIsShowAdvertiserJSX: React.ReactNode = 
        <>
            <input
                type="checkbox"
                name="catIsShowAdvertiserNameInput" 
                id="catIsShowAdvertiserInput"
                checked={catIsShowAdvertiser || false}
                onChange={(e) => setCatIsIShowAdvertiser(e.target.checked)}
            />
        </>

    const photoDateInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Дата фото"
                type="date"
                name="photoDate" 
                maxLength={10}
                id="photoDateInput"
                value={photoDate || ''}
                onChange={(e) => setPhotoDate(e.target.value)}
            />  
        </>
    
    const photoTitleInputJSX: JSX.Element = 
        <>
            <input
                className={"cat_input_fld"}
                placeholder="Заголовок фото"
                type="text"
                name="photoTitle" 
                maxLength={128}
                id="photoTitleInput"
                autoComplete="on"
                value={photoTitle || ''}
                onChange={(e) => setPhotoTitle(e.target.value)}
            />  
        </>

    const photoDescriptionInputJSX: JSX.Element = 
        <>
            <textarea
                className={"cat_description_input_fld"}
                placeholder="Описание"
                name="photoDescription" 
                maxLength={256}
                id="photoDescriptionInput"
                autoComplete="on"
                value={photoDescription || ''}
                onChange={(e) => setPhotoDescription(e.target.value)}
            />
        </>
    
    const photoIsShowAchivementInputJSX: JSX.Element = 
        <>
            <input
                type="checkbox"
                name="photoIsShowAchivement" 
                id="photoIsShowAchivementInput"
                checked={photoIsShowAchivement || false}
                onChange={(e) => setPhotoIsShowAchivement(e.target.checked)}
            />
        </>
    
    const photoIsShowPhotoInputJSX: JSX.Element = 
        <>
            <input
                type="checkbox"
                name="photoIsShowPhoto" 
                id="photoIsShowPhotoInput"
                checked={photoIsShowPhoto || false}
                onChange={(e) => setPhotoIsShowPhoto(e.target.checked)}
            />
        </>

    const dataParameterArray: Parameter[] = [
        {
            isActive: true,
            name: "Кличка",
            value: getStringValue(props.catCard.name),
            valueEdit: catNameInputJSX,
        },
        { 
            isActive: true,
            name: "Порода",
            value: getBreed(props.catCard.breed),
            valueEdit: catBreedInputJSX,
        },
        {
            isActive: true,
            name: "Окрас",
            value: getStringValue(props.catCard.coat),
            valueEdit: catCoatInputJSX,
        },
        {
            isActive: true,
            name: "Пол",
            value: getSex(getStringValue(props.catCard.sex), props.catCard.is_neuter),
            valueEdit: catSexInputJSX,
        },
        {
            isActive: true,
            name: "Дата рождения",
            value: formatDate(getStringValue(props.catCard.birthday)),
            valueEdit: catBirthdayInputJSX,
        },
        {
            isActive: false,
            name: "Отец",
            value: getStringValue(props.catCard.sir_name),
            valueEdit: catSirNameJSX,
        },
        {
            isActive: false,
            name: "Окрас отца",
            value: getStringValue(props.catCard.sir_coat),
            valueEdit: catSirCoatJSX,
        },
        {
            isActive: false,
            name: "Мать",
            value: getStringValue(props.catCard.dam_name),
            valueEdit: catDamNameJSX,
        },
        {
            isActive: false,
            name: "Окрас матери",
            value: getStringValue(props.catCard.dam_coat),
            valueEdit: catDamCoatJSX,
        },
        {
            isActive: true,
            name: "Цена в любимцы",
            value: (isPrice(props.catCard.pet_price)) ? formatPrice(getStringValue(props.catCard.pet_price)) : "",
            valueEdit: catPetPriceInputJSX,
        },
        {
            isActive: true,
            name: "Цена для племенной работы",
            value: (isPrice(props.catCard.breed_price)) ? formatPrice(getStringValue(props.catCard.breed_price)) : "",
            valueEdit: catBreedPriceInputJSX,
        },
        {
            isActive: true,
            name: "Статус",
            value: getStatus(getStringValue(props.catCard.sale_status)),
            valueEdit: catSaleStatusInputJSX,
        },
        {
            isActive: true,
            name: "Описание",
            value: getStringValue(props.catCard.adv_text),
            valueEdit: catAdvTextInputJSX
        },
        {
            isActive: props.callFrom === "MyNursery",
            name: "Показывать питомца в объявлениях",
            value: (props.catCard.is_show_advertiser) ? "Да" : "Нет",
            valueEdit: catIsShowAdvertiserJSX
        },
    ]
  
    const photoUploadJSX: JSX.Element = <></>

    const cardText = dataParameterArray.map((item, index) => (
        (item.isActive) ?
            <tr className="row" key={index}>
                <td className="cell1">{item.name}</td>
                <td className="cell2">{(isEditModeText) ? item.valueEdit : item.value}</td>
            </tr> : <></>
    ));

    const appendCatPhoto = async () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);

        const request = {
            "cat": props.catCard.id,
            path_file: ""
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' ,
                'Accept': 'application/json',
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        await fetch("https://mitrapro.tech/cats_photo_only/", requestOptions)
            .then(async (response) => {
                const data = response.json();
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return data;
            })
            .then (data => {
                const newPhoto: CatPhoto = {
                    id: data["id"],
                    cat: data["cat"],
                    path_file: data["path_file"],
                    date: data["date"],
                    title: data["title"],
                    description: data["description"],
                    is_show_achivement: data["is_show_achivement"],
                    is_show_photo: data["is_show_photo"],
                }

                props.onLockNavButton(true);
                props.onAppendPhoto(newPhoto);
                setIndexEditModePhoto(0);

            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };

    const handlerClickAppendPhotoButton = () => {
        appendCatPhoto();
    };
    
    const saveCatOnTheServer = () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);

        const request = {
            cat_status: catStatus,
            breed: catBreed,
            name: catName,
            sir_id: catSirID,
            sir_name: catSirName,
            sir_coat: catSirCoat,
            dam_id: catDamID,
            dam_name: catDamName,
            dam_coat: catDamCoat,
            birthday: catBirthday,
            pet_price: catPetPrice,
            breed_price: catBreedPrice,
            coat: catCoat,
            sex: catSex,
            sale_status: catSaleStatus,
            is_neuter: catIsNeuter,
            adv_text: catAdvText,
            is_show_advertiser: catIsShowAdvertiser
        };

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch(`https://mitrapro.tech/cats/${props.catCard.id}/`, requestOptions)
            .then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    setServerMessage(data["detail"] || "");
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return data;
            })
            .then(data => {

                const newCatCard: CatCard = {
                    id: data["id"],
                    cat_nursery: data["cat_nursery"],
                    cat_status: data["cat_status"],
                    breed: data["breed"],
                    name: data["name"],
                    sir_id: data["sir_id"],
                    sir_name: data["sir_name"],
                    sir_coat: data["sir_coat"],
                    dam_id: data["dam_id"],
                    dam_name: data["dam_name"],
                    dam_coat: data["dam_coat"],
                    birthday: data["birthday"],
                    pet_price: data["pet_price"],
                    breed_price: data["breed_price"],
                    coat: data["coat"],
                    sex: data["sex"],
                    sale_status: data["sale_status"],
                    is_neuter: data["is_neuter"],
                    adv_text: data["adv_text"],
                    is_show_advertiser: data["is_show_advertiser"],
                    phone_number: props.catCard.phone_number,
                    photos: props.catCard.photos,
                }
                
                props.onUpdate(newCatCard);
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };

    const makeCatByFirstOnTheServer = () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);
        
        const request = {
            name: props.catCard.name,
        };

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch(`https://mitrapro.tech/cats/${props.catCard.id}/`, requestOptions)
            .then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    setServerMessage(data["detail"] || "");
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return data;
            })
            .then(data => {

                const id: number = data["id"];

                props.onMakeCatByFirst();
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };

    const deletePhotoCatOnTheServer = (index: number) => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);

        const result: boolean = window.confirm("Фото питомца с коментариями будет удалено из базы данных. Если согласны, нажмите 'OK', иначе 'Отмена'.");
        if (!result) {
            return;
        }

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${userToken}`,
            },
        };

        fetch(`https://mitrapro.tech/cats_photo_only/${props.catCard.photos[index].id}/`, requestOptions)
            .then(async (response) => {
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    setServerMessage("");
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                props.onDeletePhoto(props.catCard.photos[index].id);
            })
            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };

    const clearPhotoCatOnTheServer = (index: number) => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);
        
        const result: boolean = window.confirm("Будет удалено только фото питомца из базы данных, описание и коментарии останутся и к ним можно подгрузить новое фото. Если согласны, нажмите 'OK', иначе 'Отмена'.");
        if (!result) {
            return;
        }

        const request = {
            path_file: "",
        };

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch(`https://mitrapro.tech/cats_photo_only/${props.catCard.photos[index].id}/`, requestOptions)
            .then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    setServerMessage(data["detail"] || "");
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return data;
            })
            .then(data => {

                const newPhoto: CatPhoto = {
                    id: data["id"],
                    cat: data["cat"],
                    path_file: data["path_file"],
                    date: data["date"],
                    title: data["title"],
                    description: data["description"],
                    is_show_achivement: data["is_show_achivement"],
                    is_show_photo: data["is_show_photo"],
                }
                
                props.onUpdatePhoto(newPhoto);
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };

    const makePhotoByFirstOnTheServer = (index: number) => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);
        
        const request = {
            title: props.catCard.photos[index].title,
        };

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch(`https://mitrapro.tech/cats_photo_only/${props.catCard.photos[index].id}/`, requestOptions)
            .then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    setServerMessage(data["detail"] || "");
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return data;
            })
            .then(data => {

                const id: number = data["id"];

                props.onMakePhotoByFirst(id);
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };

    const savePhotoOnTheServer = () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);
        
        const request = {
            path_file: photoPathFile,
            date: photoDate,
            title: photoTitle,
            description: photoDescription,
            is_show_achivement: photoIsShowAchivement,
            is_show_photo: photoIsShowPhoto,
        };

        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch(`https://mitrapro.tech/cats_photo_only/${photoID}/`, requestOptions)
            .then(async (response) => {
                const data = await response.json();
                if (!response.ok) {
                    setIsServerMessageVisible(true);
                    setServerMessage(data["detail"] || "");
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return data;
            })
            .then(data => {
                setPhotoID(data["id"]);
                setPhotoCat(data["cat"]);
                setPhotoPathFile(data["path_file"]);
                setPhotoDate(data["date"]);
                setPhotoTitle(data["title"]);
                setPhotoDescription(data["description"]);
                setPhotoIsShowAchivement(data["is_show_achivement"]);
                setPhotoIsShowPhoto(data["is_show_photo"]);

                const newPhoto: CatPhoto = {
                    id: data["id"],
                    cat: data["cat"],
                    path_file: data["path_file"],
                    date: data["date"],
                    title: data["title"],
                    description: data["description"],
                    is_show_achivement: data["is_show_achivement"],
                    is_show_photo: data["is_show_photo"],
                }
                
                props.onUpdatePhoto(newPhoto);
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };

    const handlerClickEditButtonText = () => {
        setEditModeText(true);
        props.onLockNavButton(true);

        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);

    }

    const handlerClickClearPhotoButton = (index: number) => {
        clearPhotoCatOnTheServer(index);
    };

    const handlerClickDeleteButtonPhoto = (index: number) => {
        deletePhotoCatOnTheServer(index);
    };

    const handlerClickMakeFirstButtonText = () => {
        makeCatByFirstOnTheServer();

    }

    const handlerClickMakeFirstButtonPhoto = (index: number) => {
        makePhotoByFirstOnTheServer(index);
    }

    const handlerClickEditButtonPhoto = (index: number) => {
        setPhotoIDInit(props.catCard.photos[index].id);
        setPhotoCatInit(props.catCard.photos[index].cat);
        setPhotoPathFileInit(props.catCard.photos[index].path_file);
        setPhotoDateInit(props.catCard.photos[index].date);
        setPhotoTitleInit(props.catCard.photos[index].title);
        setPhotoDescriptionInit(props.catCard.photos[index].description);
        setPhotoIsShowAchivementInit(props.catCard.photos[index].is_show_achivement);
        setPhotoIsShowPhotoInit(props.catCard.photos[index].is_show_photo);

        setPhotoID(props.catCard.photos[index].id);
        setPhotoCat(props.catCard.photos[index].cat);
        setPhotoPathFile(props.catCard.photos[index].path_file);
        setPhotoDate(props.catCard.photos[index].date);
        setPhotoTitle(props.catCard.photos[index].title);
        setPhotoDescription(props.catCard.photos[index].description);
        setPhotoIsShowAchivement(props.catCard.photos[index].is_show_achivement);
        setPhotoIsShowPhoto(props.catCard.photos[index].is_show_photo);

        setIndexEditModePhoto(index);
        props.onLockNavButton(true);
    };

    const handlerClickSaveButtonText = () => {
        setEditModeText(false);
        props.onLockNavButton(false);
        if (isChangeCat()) saveCatOnTheServer();
    }

    const handlerClickSaveButtonPhoto = () => {
        setIndexEditModePhoto(null);
        props.onLockNavButton(false);
        if (isChangePhoto()) savePhotoOnTheServer();
    }

    const handlerClickCancelButtonText = () => {
        setEditModeText(false);
        props.onLockNavButton(false);

        setServerMessage(null);
        setIsServerMessageVisible(false);
        setError(null);
    }

    const handlerClickCancelButtonPhoto = () => {
        setIndexEditModePhoto(null);
        props.onLockNavButton(false);
        
        setPhotoID(photoIDInit);
        setPhotoCat(photoCatInit);
        setPhotoPathFile(photoPathFileInit);
        setPhotoDate(photoDateInit);
        setPhotoTitle(photoTitleInit);
        setPhotoDescription(photoDescriptionInit);
        setPhotoIsShowAchivement(photoIsShowAchivementInit);
        setPhotoIsShowPhoto(photoIsShowPhotoInit);

        setServerMessage(null);
        setIsServerMessageVisible(false);
        setError(null);
    }

    const editButtonJSXText: JSX.Element = 
        <>
            <button className='escd_button' 
                onClick={handlerClickEditButtonText} 
                disabled={isEditModeText || indexEditModePhoto !== null || loading}
            >
                
                <img src={"/edit_button.png"} alt={"E"} />
            
            </button>
        </>
    
    const makeFirstButtonJSXText: React.ReactNode = 
        <>
            <button className='escd_button' 
                onClick={handlerClickMakeFirstButtonText} 
                disabled={isEditModeText || indexEditModePhoto !== null || loading}
            >
                
                <img src={"/first.png"} alt={"E"} />
            
            </button>
        </>
    
    const editButtonJSXPhoto = (index: number): React.ReactNode => {
        return (
            <>
                <button className='escd_button' 
                    onClick={() => handlerClickEditButtonPhoto(index)} 
                    disabled={isEditModeText || indexEditModePhoto !== null || loading }
                >

                    <img src={"/edit_button.png"} alt={"E"} />
                
                </button>
            </>
        );
    };
    
    const deleteButtonJSXPhoto = (index: number): React.ReactNode => {
        return (
            <>
                <button className='escd_button' 
                    onClick={() => handlerClickDeleteButtonPhoto(index)} 
                    disabled={isEditModeText || indexEditModePhoto !== null || loading }
                >

                    <img src={"/delete_button.png"} alt={"D"} />
                
                </button>
            </>
        );
    };

    const clearPhotoButtonJSXPhoto = (index: number): React.ReactNode => {
        return (
            <>
                <button className='escd_button' 
                    onClick={() => handlerClickClearPhotoButton(index)} 
                    disabled={isEditModeText || indexEditModePhoto !== null || loading }
                >

                    <img src={"/clear_photo.png"} alt={"D"} />
                
                </button>
            </>
        );
    };

    const makeFirstButtonJSXPhoto = (index: number): React.ReactNode => {
        return (
            <>
                <button className='escd_button' 
                    onClick={() => handlerClickMakeFirstButtonPhoto(index)} 
                    disabled={isEditModeText || indexEditModePhoto !== null || loading }
                >

                    <img src={"/first.png"} alt={"D"} />
                
                </button>
            </>
        );
    };
    
    const saveButtonJSXText: JSX.Element = 
        <>
            <button className='escd_button' 
                onClick={handlerClickSaveButtonText} 
                disabled={ !isEditModeText || indexEditModePhoto !== null || loading }
            >
                
                {loading ? 
                    <span className="loader"></span> : 
                        <img src={"/save_button.png"} alt={"S"} />
                }
            
            </button>
        </>
        //disabled={( !(isEditModeText && indexEditModePhoto === null ) || loading )}
    
    const saveButtonJSXPhoto = (index: number): React.ReactNode => {
        return (
            <>
                <button className='escd_button' 
                    onClick={handlerClickSaveButtonPhoto} 
                    disabled={ isEditModeText || indexEditModePhoto === null || (indexEditModePhoto !== null && indexEditModePhoto !== index) || loading }
                >
                
                    {loading ? 
                        <span className="loader"></span> : 
                            <img src={"/save_button.png"} alt={"S"} />
                    }
                </button>
            </>
        );
    };
    
    const cancelButtonJSXText: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickCancelButtonText} 
                disabled={ !isEditModeText || indexEditModePhoto !== null || loading }>

                <img src={"/cancel_button.png"} alt={"C"} />
            </button>
        </>
    
    const cancelButtonJSXPhoto = (index: number): React.ReactNode => {
        return(
            <>
                <button className='escd_button' onClick={handlerClickCancelButtonPhoto} 
                    disabled={ isEditModeText || indexEditModePhoto === null || (indexEditModePhoto !== null && indexEditModePhoto !== index) || loading }>

                    <img src={"/cancel_button.png"} alt={"C"} />
                </button>
            </>
        );
    };
    
    const escdButtonBoxJSXText: JSX.Element = 
        <>
            <div className="escd_button_block">
                {editButtonJSXText}
                {saveButtonJSXText}
                {cancelButtonJSXText}
                {(props.indexArrayCards !== 0) && makeFirstButtonJSXText}
            </div>
        </>
    
    const escdButtonBoxJSXPhoto = (index: number): React.ReactNode => {
        return (
            <>
                <div className="escd_button_block">
                    {editButtonJSXPhoto(index)}
                    {saveButtonJSXPhoto(index)}
                    {cancelButtonJSXPhoto(index)}
                    {deleteButtonJSXPhoto(index)}
                    {clearPhotoButtonJSXPhoto(index)}
                    {(index !== 0) && makeFirstButtonJSXPhoto(index)}
                </div>
            </>
        );
    };

    const lockInputFile: boolean = isEditModeText || indexEditModePhoto !== null || loading


    const photoListJSX = (!props.catCard.photos || props.catCard.photos.length === 0) ? <></> : 
        props.catCard.photos.map((item: CatPhoto, index: number) =>
            <>
                <div key={index} className="photo_card">
                    {props.callFrom === "MyNursery" && escdButtonBoxJSXPhoto(index)}
                    <h3>{`Фото ${index+1}`}</h3>
                    <tr className="row" key={index}>
                        <td className="cell1">{"Дата фото"}</td>
                        <td className="cell2_photo">{(indexEditModePhoto === index) ? photoDateInputJSX : formatDate(getStringValue(item.date), false)}</td>
                    </tr>
                    <tr className="row" key={index}>
                        <td className="cell1">{"Заголовок"}</td>
                        <td className="cell2_photo">{(indexEditModePhoto === index) ? photoTitleInputJSX : item.title}</td>
                    </tr>
                    
                    { (item.path_file.trim().length >0) ? 
                        <img alt={"ph"} src={item.path_file} className="full_photo_cat"/> : 
                            <div className="place_photo_cat">
                                <UploadPhoto key={index} catPhotoID={item.id} onUploadPhoto={handlePhotoPathFileUpdate} lockInputFile={lockInputFile} />
                            </div>
                    }
                    
                    <tr className="row" key={index}>
                        <td className="cell1">{"Описание"}</td>
                        <td className="cell2_photo">{(indexEditModePhoto === index) ? photoDescriptionInputJSX : item.description}</td>
                    </tr>

                    {(props.callFrom === "MyNursery") ? 
                        <>
                            <tr className="row" key={index}>
                                <td className="cell1">{"Показывать это фото в достижениях"}</td>
                                <td className="cell2_photo">{(indexEditModePhoto === index) ? photoIsShowAchivementInputJSX : (item.is_show_achivement) ? "Да" : "Нет"}</td>
                            </tr>
                            <tr className="row" key={index}>
                                <td className="cell1">{"Показывать это фото в разделе фотографии"}</td>
                                <td className="cell2_photo">{(indexEditModePhoto === index) ? photoIsShowPhotoInputJSX : (item.is_show_photo) ? "Да" : "Нет"}</td>
                            </tr>
                        </>
                        : null
                    }
                    
                </div>
            </>
    );
    
    const appendPhotoButtonJSX: JSX.Element = 
        <>
            <div className="append_photo">
                <button className='escd_button' 
                    onClick={handlerClickAppendPhotoButton} 
                    disabled={isEditModeText || indexEditModePhoto !== null}
                >
                    
                    <img src={"/append_button.png"} alt={"D"} />
                
                </button>
            </div>
        </>

    const imageJSX: JSX.Element =
        <>
            {(props.callFrom === "MyNursery") && appendPhotoButtonJSX}
            <table className="styled-table">
                <tbody>
                    {photoListJSX}
                </tbody>
            </table>

            <TelephoneNumberHiden telNum={props.catCard.phone_number || ''} 
                telNumHiden={"+Х-ХXX-XXX-XX-XX"} 
                className={"full_card_telephone_number"} />
        </>

    const cardTextBlockJSX: JSX.Element = 
        <>
            {(props.callFrom === "MyNursery") && escdButtonBoxJSXText}
            {error && <p className="error-message">{error}</p>}
            {isServerMessageVisible && <p className="error-message">{serverMessage}</p>}
            <table className="styled-table">
                <tbody>
                    {cardText}
                </tbody>
            </table>
        </>

    const handlerClickAppendButton = () => {
        if (props.callFrom === "MyNursery") {
            //createNurseryOnTheServer();
        }
    }

    const appendButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickAppendButton} 
                disabled={isEditModeText || indexEditModePhoto !== null}>
                
                <img src={"/append_button.png"} alt={"D"} />
            </button>
        </>
    
    const appendButtonBlockJSX: JSX.Element = 
        <>
            <div className="append_block">
                {appendButtonJSX}
            </div>
        </>

    return (
        <>
            <div className="new_full_card_text">
                {cardTextBlockJSX}
            </div>
                
            <div className="new_full_card_image">
                {imageJSX}
            </div>
            
        </>
    );
}
