import { SetStateAction, useEffect, useState } from "react";
import PetShortCard from "./PetShortCard";
import CatPhotoCard from "./CatPhotoCard";
import CatNurseryShortCard from "./CatNurseryShortCard";
import CatNurseryFullCard from "./CatNurseryFullCard";
import CatFullCard from "./CatFullCard";
import { CatCard, CatPhoto, DeleteButtonProps, NurseryCard, ObjectCatStatus, ObjectListFrom, ObjectListTypeCard } from "./CommonData/DataTypes";
import { useSelector } from "react-redux";
import { paginationServer } from "./CommonData/Constants"

//interface ObjectProps<T> {
//    url: string;
//    mapData: (data: any) => T[]; // Функция для преобразования данных в нужный формат
//}

interface ObjectProps {
    url: string;
    dataType: ObjectListTypeCard;
    callFrom: ObjectListFrom;
    catStatus: ObjectCatStatus | null;
    parentID: number | null;
    isAppend: boolean;
}

export default function ObjectList(props: ObjectProps): JSX.Element {
    const { userID, userNic, userLogin, userIsAuthenticated, userToken, userPhoneNumber } = useSelector((state: any) => state.user);
    
    
    const [paginationLastPage, setPaginationLastPage] = useState<number>(0);
    const [paginationActivePage, setPaginationActivePage] = useState<number>(1);
    const [urlPreviousPage, setURLPreviousPage] = useState<string | null>(null);
    const [urlNextPage, setURLNextPage] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [serverMessage, setServerMessage] = useState<string | null>(null);
    const [isServerMessageVisible, setIsServerMessageVisible] = useState<boolean>(false);

    const [objectArray, setObjectArray] = useState<CatCard[] | CatPhoto[] | NurseryCard[]>([]);

    const [showObjectWindow, setShowObjectWindow] = useState<number>(-1);
    const [lockNuvButton, setLockNuvButton] = useState<boolean>(false);

    function getPaginationItem(activePage: number, lastPage: number, index: number = 0): number {
        var paginationArr: (number)[] = [0, 0, 0, 0, 0];

        if (lastPage === 2) {
            paginationArr = [-1, -1, -1, -1, -1];
        }

        if (lastPage === 3) {
            paginationArr = [-1, -1, 2, -1, -1];
        }

        if (lastPage === 4) {
            paginationArr = [-1, 2, 3, -1, -1];
        }

        if (lastPage === 5) {
            paginationArr = [-1, 2, 3, 4, -1];
        }

        if (lastPage === 6) {
            paginationArr = [2, 3, 4, -1, 5];
        }

        if (lastPage === 7) {
            paginationArr = [2, 3, 4, 5, 6];
        }

        if (lastPage > 7) {
            if (activePage <= 4) {
                paginationArr = [2, 3, 4, 5, 6];
            } else {
                if (activePage >= lastPage - 3) {
                    paginationArr = [ lastPage - 5, 
                                        lastPage - 4, 
                                        lastPage - 3, 
                                        lastPage - 2, 
                                        lastPage - 1
                                    ];
                } else {
                    paginationArr = [ activePage - 2, 
                        activePage - 1,
                        activePage,
                        activePage + 1,
                        activePage + 2
                    ];
                }
            }
            
            if (paginationArr[0] !== 2) {
                paginationArr[0] = 0;
            }

            if (paginationArr[4] !== lastPage - 1) {
                paginationArr[4] = 0;
            }
        }

        return paginationArr[index];
    }

    const handlerCloseErrorWindow = () => {
        setError(null);
    }

    const handlerCloseNurseryWindow = () => {
        setShowObjectWindow(-1);
    }

    const handlerClosePetCardWindow = () => {
        setShowObjectWindow(-1);
    }

    const handleSetNavButton = (lockNavButton: boolean) => {
        setLockNuvButton(lockNavButton);
    };

    const handleUpdateCatCard = (updatedCatCard: CatCard) => {
        let updatedArray: SetStateAction<CatCard[] | CatPhoto[] | NurseryCard[]> = [];
        
        updatedArray.unshift(updatedCatCard as CatCard & CatPhoto & NurseryCard);
        for (let i = 0; i < objectArray.length; i++) {
            if (objectArray[i].id !== updatedCatCard.id) {
                updatedArray.push(objectArray[i] as CatCard & CatPhoto & NurseryCard);
            }
        }

        setObjectArray(updatedArray);
        setShowObjectWindow(0);

    };

    const handleUpdateNurseryCard = (updatedNurseryCard: NurseryCard) => {
        let updatedArray: SetStateAction<CatCard[] | CatPhoto[] | NurseryCard[]> = [];
        let nurseryCard: NurseryCard;

        for (let i = 0; i < objectArray.length; i++) {
            nurseryCard = objectArray[i] as NurseryCard;
            if (nurseryCard.id === updatedNurseryCard.id ) {
                updatedArray.unshift(updatedNurseryCard as CatCard & CatPhoto & NurseryCard);
            } else {
                updatedArray.push(updatedNurseryCard as CatCard & CatPhoto & NurseryCard);
            }
        }

        setObjectArray(updatedArray);
        setShowObjectWindow(0);
    }

    const handleMakeCatByFirst = () => {
        let updatedArray: SetStateAction<CatCard[] | CatPhoto[] | NurseryCard[]> = [];
        let catCard: CatCard;
        for (let i = 0; i < objectArray.length; i++) {
            catCard = objectArray[i] as CatCard;
            if (catCard.id === objectArray[showObjectWindow].id ) {
                updatedArray.unshift(catCard as CatCard & CatPhoto & NurseryCard);
            } else {
                updatedArray.push(catCard as CatCard & CatPhoto & NurseryCard);
            }
        }

        setObjectArray(updatedArray);
        setShowObjectWindow(0);
    };

    const viewArray = (title: string, array: CatCard[]) => {
        alert(title);
        for (let i = 0; i < array.length; i++) {
            alert(`Array[${i}].id=${array[i].id}`);
        }
    }

    const handleAppendCat = (appendedItem: CatCard) => {
        //viewArray("Массив до:", objectArray as CatCard[]);
        let updatedArray: CatCard[] = [];
        updatedArray.unshift(appendedItem as CatCard & CatPhoto & NurseryCard);
        for (let i = 0; i < objectArray.length; i++) {
            updatedArray.push(objectArray[i] as CatCard & CatPhoto & NurseryCard);
        }

        //viewArray("Массив после:", updatedArray as CatCard[]);

        setObjectArray(updatedArray);
        setShowObjectWindow(0);
    };

    const handleDeletePhoto = (photoID: number) => {
        let updatedArray: SetStateAction<CatCard[] | CatPhoto[] | NurseryCard[]> = [];
        let catCard: CatCard;
        let catPhoto: CatPhoto;
        for (let i = 0; i < objectArray.length; i++) {
            catCard = objectArray[i] as CatCard;
            if (catCard.photos && catCard.photos.length > 0) {
                for (let j = 0; j < catCard.photos.length; j++) {
                    if (catCard.photos[j].id === photoID) {
                        catCard.photos.splice(j, 1);
                    }
                }
            }
            updatedArray.push(catCard as CatCard & CatPhoto & NurseryCard);
        }

        setObjectArray(updatedArray);
    };

    const handleMakePhotoByFirst = (firstPhotoID: number) => {
        let updatedArray: SetStateAction<CatCard[] | CatPhoto[] | NurseryCard[]> = [];
        let catCard: CatCard;
        let catPhoto: CatPhoto;
        for (let i = 0; i < objectArray.length; i++) {
            catCard = objectArray[i] as CatCard;
            if (catCard.photos && catCard.photos.length > 0) {
                for (let j = 0; j < catCard.photos.length; j++) {
                    if (catCard.photos[j].id === firstPhotoID) {
                        catPhoto = catCard.photos[j];
                        catCard.photos.splice(j, 1);
                        catCard.photos.unshift(catPhoto);
                    }
                }
            }
            updatedArray.push(catCard as CatCard & CatPhoto & NurseryCard);
        }

        setObjectArray(updatedArray);
    };

    const handlePhotoAppend = (appendedItem: CatPhoto) => {
        let updatedArray: SetStateAction<CatCard[] | CatPhoto[] | NurseryCard[]> = [];
        let catCard: CatCard;
        for (let i = 0; i < objectArray.length; i++) {
            catCard = objectArray[i] as CatCard;
            if (catCard.id === appendedItem.cat) {
                if (!catCard.photos) {
                    catCard.photos = [];
                }
                catCard.photos.unshift(appendedItem);
            }
            updatedArray.push(catCard as CatCard & CatPhoto & NurseryCard);
        }

        setObjectArray(updatedArray);
    };


    const handlePhotoUpdate = (updatedItem: CatPhoto) => {
        let updatedArray: SetStateAction<CatCard[] | CatPhoto[] | NurseryCard[]> = [];
        let catCard: CatCard;
        for (let i = 0; i < objectArray.length; i++) {
            catCard = objectArray[i] as CatCard;
            if (catCard.photos && catCard.photos.length > 0) {
                for (let j = 0; j < catCard.photos.length; j++) {
                    if (catCard.photos[j].id === updatedItem.id) {
                        catCard.photos[j] = updatedItem;
                    }
                }
            }
            updatedArray.push(catCard as CatCard & CatPhoto & NurseryCard);
        }

        setObjectArray(updatedArray);
    };

    const appendCat = () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);

        const request = {
            "cat_nursery": props.parentID,
            "cat_status": props.catStatus ?? "KITTEN",
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' ,
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch("https://mitrapro.tech/cats/", requestOptions)
            .then(async (response) => {
                const data = response.json();
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return data;
            })
            .then(data => {
                const newCatCard: CatCard = {
                    id: data['id'],
                    cat_nursery: data['cat_nursery'],
                    cat_status: data['cat_status'],
                    breed: data['breed'],
                    name: data['name'],
                    sir_id: data['sir_id'],
                    sir_name: data['sir_name'],
                    sir_coat: data['sir_coat'],
                    dam_id: data['dam_id'],
                    dam_name: data['dam_name'],
                    dam_coat: data['dam_coat'],
                    birthday: data['birthday'],
                    pet_price: data['pet_price'],
                    breed_price: data['breed_price'],
                    coat: data['coat'],
                    sex: data['sex'],
                    sale_status: data['sale_status'],
                    is_neuter: data['is_neuter'],
                    is_show_advertiser: data['is_show_advertiser'],
                    adv_text: data['adv_text'],
                    phone_number: data['phone_number'],
                    photos: []
                };

                handleAppendCat(newCatCard);
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };

    function requestObjectList(url: string, isFirstRecord: boolean = true) {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки
        
        const replaceHttpWithHttps = (url: string | null): string | null => {
            if (url === null) {
                return url;
            } else {
                return url.replace("http://", "https://");
            }
        };

        const isPageInclude = (url: string | null): boolean => {
            if (url === null) return false;
            return url.includes("?page=");
        }
        
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
  
        fetch(url, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                return response.json();
            })
            .then(data => {
                let forPaginationLastPage = Math.ceil(data["count"] / paginationServer);
                let forURLNextPage = replaceHttpWithHttps(data["next"]);
                let forURLPreviousPage = replaceHttpWithHttps(data["previous"]);
                let forPaginationActivePage = 1;
                let forShowObjectWindow: number = -1;
                //let forActivePhoto = 0;
                
                let activePageSetting: boolean = false;

                if (!activePageSetting && forURLPreviousPage === null) {
                    forPaginationActivePage=1;
                    activePageSetting = true;
                }

                if (!activePageSetting && !isPageInclude(forURLPreviousPage)) {
                    forPaginationActivePage=2;
                    activePageSetting = true;
                }
                
                if (!activePageSetting && forURLNextPage === null) {
                    forPaginationActivePage=forPaginationLastPage;
                    activePageSetting = true;
                } 
                    
                if (!activePageSetting && forURLNextPage !== null) {
                    forPaginationActivePage=parseInt(forURLNextPage.split("?page=")[1], 10) - 1;
                    activePageSetting = true;
                }
                    
                if (!activePageSetting && forURLPreviousPage !== null) {
                    forPaginationActivePage=parseInt(forURLPreviousPage.split("?page=")[1], 10) + 1;
                    activePageSetting = true;
                }
                
                setObjectArray([...data["results"]]);
                const arr = data["results"];
                //const arr = props.mapData(data["results"]); // Преобразование данных через mapData
                //setObjectArray([...arr]);
                
                if (showObjectWindow >= 0 && arr.length > 0) {
                    if (isFirstRecord) {
                        forShowObjectWindow = 0;
                        //if (arr[0].photos.length > 0) {
                        //    forActivePhoto = arr[0].photos[0].id;
                        //} 
                    } else {
                        forShowObjectWindow = arr.length - 1;
                        //if (arr[arr.length - 1].photos.length > 0) {
                        //    forActivePhoto = arr[arr.length - 1].photos[0].id;
                        //}
                    } 
                }

                setPaginationLastPage(forPaginationLastPage);
                setURLNextPage(forURLNextPage);
                setURLPreviousPage(forURLPreviousPage);
                setPaginationActivePage(forPaginationActivePage);
                setShowObjectWindow(forShowObjectWindow);
                //setActivePhoto(forActivePhoto);
            })
            .catch(error => {
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    //React.MouseEvent<HTMLDivElement>
    const handlerClick = (event: React.MouseEvent<HTMLDivElement>, index: number ) => {
        event.stopPropagation(); // Останавливаем всплытие
        if (props.dataType !== "CatPhoto") {
            setShowObjectWindow(index);
        }
    }

    const handlerPrevRecord = () => {
        if (showObjectWindow > 0) {
            setShowObjectWindow(showObjectWindow - 1);
        } else {
            if (urlPreviousPage !== null) {
                requestObjectList(urlPreviousPage, false);
            }
        }
    }

    const handlerNextRecord = () => {
        if (showObjectWindow < objectArray.length - 1) {
            setShowObjectWindow(showObjectWindow + 1);
        } else {
            if (urlNextPage !== null) {
                requestObjectList(urlNextPage);
            }
        }
    }

    const handlerPrevPage = () => {
        if (urlPreviousPage !== null) {
            requestObjectList(urlPreviousPage);
        }
    }

    const handlerNextPage = () => {
        if (urlNextPage !== null) {
            requestObjectList(urlNextPage);
        }
    }

    const handlerSetPage = (num:number) => {
        if (num === paginationActivePage || paginationLastPage=== 1) return;

        if (num > 0 && num <= paginationLastPage) {
            let url: string = "";
            if (urlNextPage !== null) {
                url = urlNextPage.replace(/(\?page=)\d+/, `$1${num}`);
            } else {
                if (urlPreviousPage !== null) {
                    url = urlPreviousPage.replace(/(\?page=)\d+/, `$1${num}`);
                }
            }
            requestObjectList(url);
            return;
        }
    }


    const handlerClickDeleteCatCardButton = (event: React.MouseEvent<HTMLButtonElement>, index: number ) => {
        event.stopPropagation();
        const id: number = objectArray[index].id;
        
        const result: boolean = window.confirm("Питомец будет удален из базы данных. Если согласны, нажмите 'OK', иначе 'Отмена'.");
        if (!result) {
            return;
        }

        const catCard = objectArray[index] as CatCard;

        if (catCard.photos && catCard.photos.length > 0) {
            alert("У питомца есть фото, которые нужно удалить перед удалением питомца. Для удаления фото питомцев откройте карточку питомца.");
            return;
        }

        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json' ,
                'Authorization': `Token ${userToken}`,
            },
        };
  
        fetch(`https://mitrapro.tech/cats/${id}/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Статус: ${response.status}`);
                }
                setObjectArray(prevArray => 
                    (prevArray as CatCard[]).filter(item => item.id !== id)
                );
                //return;
            })
            .catch(error => {
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }
    

    const handlerClickDeleteNurseryButton = (event: React.MouseEvent<HTMLButtonElement>, index: number ) => {
        event.stopPropagation();
        const id: number = objectArray[index].id;
        
        const result: boolean = window.confirm("Питомник будет удален. Если согласны, нажмите 'OK', иначе 'Отмена'.");
        if (!result) {
            return
        }

        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json' ,
                'Authorization': `Token ${userToken}`,
            },
        };
  
        fetch(`https://mitrapro.tech/cat_nursery/${id}/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Запрос не прошёл. Статус: ${response.status}`);
                }
                setObjectArray(prevArray => 
                    (prevArray as NurseryCard[]).filter(item => item.id !== id)
                );
                //return;
            })
            .catch(error => {
                setError(error.message || 'Произошла ошибка');
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    }

    const isShowDeleteButton: boolean = (props.callFrom === "MyNursery");

    const deleteButtonComponent: React.FC<DeleteButtonProps> = ({ id, onDelete }) => {
        return (
            <>
                <div className="delete_button_element">
                    <button className='escd_button_delete' 
                        onClick={(event) => onDelete(event, id)} 
                        disabled={false}>
                        <img src={"/delete_record_red_button.png"} alt={"D"} />
                    </button>
                </div>
            </>
        );
    }

    const paginationItem1JSX: JSX.Element = 
        <>
            <div className={(getPaginationItem(paginationActivePage, paginationLastPage, 0) === 0) ? "not_page_number" : 
                (paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 0)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 0))}>
            
                {(getPaginationItem(paginationActivePage, paginationLastPage, 0) > 0) ? getPaginationItem(paginationActivePage, paginationLastPage, 0) : "..."}
        
            </div>
        </>

    const paginationItem2JSX: JSX.Element = 
        <>
            <div className={(paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 1)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 1))}>
            
                {getPaginationItem(paginationActivePage, paginationLastPage, 1)}
            </div>
        </>

    const paginationItem3JSX: JSX.Element = 
        <>
            <div className={(paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 2)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 2))}>
            
                {getPaginationItem(paginationActivePage, paginationLastPage, 2)}

            </div>
        </>

    const paginationItem4JSX: JSX.Element = 
        <>
            <div className={(paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 3)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 3))}>

                {getPaginationItem(paginationActivePage, paginationLastPage, 3)}

            </div>
        </>

    const paginationItem5JSX: JSX.Element = 
        <>
            <div className={(getPaginationItem(paginationActivePage, paginationLastPage, 4) === 0) ? "not_page_number" :
                (paginationActivePage === getPaginationItem(paginationActivePage, paginationLastPage, 4)) ? "page_number_active" : "page_number"}
                onClick={() => handlerSetPage(getPaginationItem(paginationActivePage, paginationLastPage, 4))}>

                {((getPaginationItem(paginationActivePage, paginationLastPage, 4) > 0)) ? getPaginationItem(paginationActivePage, paginationLastPage, 4) : "..."}
        
            </div>
        </>


    const paginationBlock: JSX.Element = 
        <>
            <div className="pagination_block">
                <div className="pagination_bar">
                    <button className={`paginator_button ${paginationActivePage === 1 ? 'disabled' : ''}`} 
                        disabled={paginationActivePage===1}>
        
                        <img src="/button_left.png" alt="Left" 
                            onClick={() => handlerPrevPage()}/>
    
                    </button>
                    <div className={(paginationActivePage === 1) ? "page_number_active" : "page_number"}
                        onClick={() => handlerSetPage(1)}>
                    
                        {"1"}

                    </div>
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 0) >= 0) && paginationItem1JSX}
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 1) >= 0) && paginationItem2JSX}
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 2) >= 0) && paginationItem3JSX}
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 3) >= 0) && paginationItem4JSX}
            
                    {(getPaginationItem(paginationActivePage, paginationLastPage, 4) >= 0) && paginationItem5JSX}
            
                    <div className={(paginationActivePage === paginationLastPage) ? "page_number_active" : "page_number"}
                        onClick={() => handlerSetPage(paginationLastPage)}>

                        {paginationLastPage}
                    </div>             
                
                    <button className={`paginator_button ${paginationActivePage === paginationLastPage ? 'disabled' : ''}`} 
                        disabled={paginationActivePage===paginationLastPage}>
        
                        <img src="/button_right.png" alt="Right"
                            onClick={() => handlerNextPage()}/>

                    </button>
                </div> 
            </div>
        </>

    const lockPreviosRecordButton: boolean = (showObjectWindow === 0 && paginationActivePage === 1) || lockNuvButton;
    const lockNextRecordButton: boolean = (showObjectWindow === objectArray.length - 1 && paginationActivePage === paginationLastPage) || lockNuvButton;
    
    const previosRecordButtonJSX: JSX.Element = 
        <>
            <div className="full_card_button">
                <button 
                    className={`full_card_step_button ${lockPreviosRecordButton ? 'disabled' : ''}`} 
                    disabled={lockPreviosRecordButton}
                    onClick={handlerPrevRecord}>
                            
                    <img src="/button_left.png" alt="Left"/
                >
                        
                </button>
            </div>
        </>
    
    const nextRecordButtonJSX: JSX.Element =
        <>
            <div className="full_card_button">
                <button                             
                    className={`full_card_step_button ${lockNextRecordButton ? 'disabled' : ''}`} 
                    disabled={lockNextRecordButton}
                    onClick={handlerNextRecord}
                >
                            
                    <img src="/button_right.png" alt="Right"/>
                    
                </button>
            </div>
        </>
    
    const closeButtonJSX: JSX.Element = 
        <>
            <button 
                className="modal_close_button_5000"
                onClick={() => handlerClosePetCardWindow()}
            >
                        
                <img src="/close_black_button.png" alt="Left"/>
                        
            </button>
        </>
    
    const showFullCatCardWindowJSX: JSX.Element = 
        <>
            <div className="full_card_modal_overlay"> 
                {closeButtonJSX} 
                <div className='full_card_modal_content_common'>
                    <div className="full_card_content_common_info">
                        <CatFullCard catCard={objectArray[showObjectWindow] as CatCard}
                            indexArrayCards={showObjectWindow} 
                            callFrom={props.callFrom} 
                            onUpdate={handleUpdateCatCard}
                            onUpdatePhoto={handlePhotoUpdate}
                            onLockNavButton={handleSetNavButton}
                            onAppendPhoto={handlePhotoAppend}
                            onMakePhotoByFirst={handleMakePhotoByFirst}
                            onMakeCatByFirst={handleMakeCatByFirst}
                            onDeletePhoto={handleDeletePhoto}
                        />

                    </div>
                    
                    <div className="navigation_button_block">
                        {previosRecordButtonJSX}
                        {nextRecordButtonJSX}
                    </div>
                </div>
            </div>
        </>

    const closeButtonNurseryWindowJSX: React.ReactNode = 
        <>
            <button className="modal_close_button"
                onClick={() => handlerCloseNurseryWindow()}
            >
                <img src="/close_black_button.png" alt="Left"/>
            </button>
        </>
    
    const nurseryWindowJSX: JSX.Element =
        <>
            <div className="nursery_window_overlay">
                {closeButtonNurseryWindowJSX}
                <div className="nursery_window_content_common">
                    <CatNurseryFullCard nurseryCard={objectArray[showObjectWindow] as NurseryCard} 
                        isReadOnly={(props.callFrom !== "MyNursery")} callFrom={props.callFrom} 
                        onUpdate={handleUpdateNurseryCard}/>

                </div>
            </div>
        </>
    
    const objectListJSX: JSX.Element[] = objectArray.map((item: CatCard | CatPhoto | NurseryCard, index: number) => (
        <div key={index} 
            onClick={(event: React.MouseEvent<HTMLDivElement>) => handlerClick(event, index)} 
            className={"element"}>
    
            {(isShowDeleteButton && props.dataType === "CatCard") && deleteButtonComponent({ id: index, onDelete: handlerClickDeleteCatCardButton })}
            {(isShowDeleteButton && props.dataType === "CatNursery") && deleteButtonComponent({ id: index, onDelete: handlerClickDeleteNurseryButton })}
            
            {(props.dataType === "CatCard") && <PetShortCard petCard={item as CatCard} isFavorite={true} />}
            {(props.dataType === "CatPhoto") && <CatPhotoCard photo={item as CatPhoto} />}
            {(props.dataType === "CatNursery") && <CatNurseryShortCard nurseryCard={item as NurseryCard} />}
        </div>)
        );

    const objectFullCardJSX: JSX.Element = 
        <>
            {(props.dataType === "CatNursery") && nurseryWindowJSX }
            {(props.dataType === "CatCard") && showFullCatCardWindowJSX }
        </>

    useEffect(() => {
        requestObjectList(props.url);
      }, []);
    
    const loaderJSX : JSX.Element =
        <>
            <div className="loader_overlay">
                <span className="loader_orange"></span>
            </div>
        </>

    const errorJSX: JSX.Element =
        <>
            <div className="error_overlay">
                <div className="error_box">
                    <div className="error_text">
                        {error}
                    </div>
                    <button className="full_card_close_button"
                        onClick={() => handlerCloseErrorWindow()}
                    >
                            <img src="/close_black_button.png" alt="Left"/>
                    </button>
                </div>

            </div>
        </>
    
    const createNurseryOnTheServer = () => {
        setLoading(true);   // Устанавливаем состояние загрузки
        setError(null);     // Очищаем предыдущие ошибки

        setServerMessage(null);
        setIsServerMessageVisible(false);


        const request = {
            "name": "noname",
            "federation": "",
            "breeder_name": "",
            "location": "",
            "club": "",
            "description":""
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' ,
                'Authorization': `Token ${userToken}`,
            },
            body: JSON.stringify(request)
        };

        fetch("https://mitrapro.tech/cat_nursery/", requestOptions)
            .then(async (response) => {
                const data = response.json();
                if (!response.ok) {
                    //setIsServerMessageVisible(true);
                    //setServerMessage(data["message"]);
                    throw new Error(`Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет. Статус: ${response.status}`);
                } 
                //return response.json();
                return data;
            })
            .then(data => {
                // пример ответа сервера
                //{
                //    "id": 4,
                //    "user": 1,
                //    "name": "Forest Miracle2",
                //    "federation": "ICU",
                //    "breeder_name": "Kristina",
                //    "location": "Krasnodar",
                //    "club": "Persona Cat",
                //    "description": "Since 2017",
                //    "phone_number": "+79184633009"
                //  }
                let arr = objectArray;
                arr.unshift(data);
                setObjectArray(arr);

                setShowObjectWindow(0);
            })

            .catch(error => {
                setError(error.message || 'Произошла ошибка');
                setIsServerMessageVisible(true);
            })
            .finally(() => {
                setLoading(false); // Завершаем загрузку
            });
    };
    
    const handlerClickAppendButton = () => {
        if (props.callFrom === "MyNursery" && props.dataType === "CatNursery") {
            createNurseryOnTheServer();
        }
        if (props.callFrom === "MyNursery" && props.dataType === "CatCard") {
            appendCat();
        }
    }

    const appendButtonJSX: JSX.Element = 
        <>
            <button className='escd_button' onClick={handlerClickAppendButton} disabled={false}>
                <img src={"/append_button.png"} alt={"D"} />
            </button>
        </>
    
    const appendButtonBlockJSX: JSX.Element = 
        <>
            <div className="append_block">
                {appendButtonJSX}
            </div>
        </>
    
    const isShowAppendButton: boolean = (props.callFrom === "MyNursery" && props.isAppend);

    return (
        <>
            {isShowAppendButton && appendButtonBlockJSX}
            {loading && loaderJSX}
            {error && errorJSX}
            {(showObjectWindow >= 0) ? objectFullCardJSX : objectListJSX}
            {(paginationLastPage > 1) && paginationBlock }
        </>
    );
};